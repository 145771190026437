// Import our CSS
import 'aos/src/sass/aos.scss'
import 'swiper/css/swiper.css'
import styles from '../scss/app.scss'

//Import vue components
import { mapState, mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import AOS from 'aos';
import store from './store'


import VueAwesomeSwiper from 'vue-awesome-swiper';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    //Enable swiper
    Vue.use(VueAwesomeSwiper);

    // Create our vue instance
    // @ts-ignore
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        store,
        components: {
            'CallMe': () => import(/* webpackChunkName: "CallMe" */ './components/CallMe.vue'),
            'Contactlead': () => import(/* webpackChunkName: "Contactlead" */ './components/Contactlead.vue'),
            'Contactmap': () => import(/* webpackChunkName: "Contactmap" */ './components/Contactmap.vue'),
            'Contactperson': () => import(/* webpackChunkName: "Contactperson" */ './components/Contactperson.vue'),
            'EntryGrid': () => import(/* webpackChunkName: "EntryGrid" */ './components/EntryGrid.vue'),
            'FullpageSlider': () => import(/* webpackChunkName: "FullpageSlider" */ './components/FullpageSlider.vue'),
            'Imagepoints': () => import(/* webpackChunkName: "Imagepoints" */ './components/Imagepoints.vue'),
            'LeadForm': () => import(/* webpackChunkName: "LeadForm" */ './components/LeadForm.vue'),
            'ProjectGrid': () => import(/* webpackChunkName: "ProjectGrid" */ './components/ProjectGrid.vue'),
            'Tab': () => import(/* webpackChunkName: "Tab" */ './components/Tab.vue'),
            'Tabs': () => import(/* webpackChunkName: "Tabs" */ './components/Tabs.vue'),
            'YoutubePopup': () => import(/* webpackChunkName: "YoutubePopup" */ './components/YoutubePopup.vue'),
        },
        mixins: [clickaway],
        data() {
            return {
                megahover: false,
            }
        },
        props: {
        },
        computed: {
            ...mapState({
                isVideoOpen: (state) => state.app.isVideoOpen,
                isSliderOpen: (state) => state.app.isSliderOpen,
                sliderImages: (state) => state.app.sliderImages,
                videoId: (state) => state.app.videoId,
                isMobileMenuOpen: (state) => state.app.isMobileMenuOpen,
                showContactperson: (state) => state.app.showContactperson,
                isLanguageSelectorOpen: (state) => state.app.isLanguageSelectorOpen,
                showCallMe: (state) => state.app.showCallMe
            }),
        },
        methods: {
            openCallMe: function () {
                store.commit('app/setCallMeState', true)
            },
            openContactperson: function () {
                store.commit('app/setContactpersonState', true)
            },
            showVideo: function (videoId) {
                store.dispatch(
                    'app/showVideo',
                    videoId
                )
            },
            showSlider: function (images) {
                store.dispatch(
                    'app/showSlider',
                    images
                )
            },
            slideTo(parentRef, ref) {
                let parentSlide = this.$refs[parentRef].$swiper
                let slider = this.$refs[ref].$swiper;
                slider.slideTo(parentSlide.activeIndex)
            },
            handleScroll: function (event) {
                const app = document.getElementById('app')
                let scrollPosY = window.pageYOffset | document.body.scrollTop;

                if (app) {
                    if (scrollPosY >= 101) {
                        if (!app.classList.contains('scrolled')) {
                            app.classList.add('scrolled')
                        }
                    } else if (scrollPosY < 101) {
                        app.classList.remove('scrolled')
                    }

                    if (scrollPosY >= 84) {
                        if (!app.classList.contains('scrolled--mobile')) {
                            app.classList.add('scrolled--mobile')
                        }
                    } else if (scrollPosY < 84) {
                        app.classList.remove('scrolled--mobile')
                    }
                }
            },
            openMobileMenu() {
                store.commit('app/setMobilemenuState', true)
            },
            closeMobileMenu() {
                store.commit('app/setMobilemenuState', false)
            },
            toggleMobileElement(e) {
                e.target.parentNode.classList.toggle('open')
            },
            showTextBox: function (ref, text, headline) {
                let imagepoints = this.$refs[ref];
                imagepoints.showText(text, headline)
            },
            toggleLanguageSelectorState: function () {
                console.log('toggle')
                switch (this.isLanguageSelectorOpen) {
                    case true:
                        store.commit('app/setLanguageSelectorState', false)
                        break

                    case false:
                        store.commit('app/setLanguageSelectorState', true)
                        break

                    default:
                        store.commit('app/setLanguageSelectorState', false)
                        break
                }
            },

        },
        created() {
            AOS.init({
                easing: 'ease-in-out',
                delay: 100,
                offset: 50,
                once: true,
                startEvent: 'load',
                anchorPlacement: 'top-bottom',
                disable: function () {
                    return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
                }
            })

            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        beforeMount: function () {
            store.commit('app/setCsrfToken', window.siteData.csrf)
            store.commit('app/setTranslations', window.siteData.translations)
            store.commit('app/setSiteUrl', window.siteData.siteUrl)
            store.commit('app/setLanguageSelectorState', false)
        },
        mounted() {
        },
    });

    return vm;
};

// Execute async function
main().then((vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
